@import url("../fonts/satoshi.css");

/* ----------- FONTS Families -----------
--- Mostly Used ---
font-family: 'Satoshi-Regular';
font-family: 'Satoshi-Light';
font-family: 'Satoshi-Medium';
font-family: 'Satoshi-Bold';

--- OTHER ---
font-family: 'Satoshi-Variable';
font-family: 'Satoshi-VariableItalic';
font-family: 'Satoshi-LightItalic';
font-family: 'Satoshi-Italic';
font-family: 'Satoshi-MediumItalic';
font-family: 'Satoshi-BoldItalic';
font-family: 'Satoshi-Black';
font-family: 'Satoshi-BlackItalic'; 
----------- FONTS Families ----------- */

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: "Satoshi-Regular" !important;
  overflow-x: hidden !important;
}

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

.landingpage {
  background-image: url("../Assets/imgs/lp-dsk-bg.webp");
  background-position: center;
  background-size: cover;
  min-height: 100%;
}

.main-content {
  /* width: 100%; */
  max-width: 375px;
  text-align: center;
  margin: auto;
  padding: 20px 5px 20px 5px;
  min-height: 100vh;
  position: relative;
}

.main-img-wraper {
  position: relative;
}

.main-content .image {
  position: relative;
  z-index: 1;
}

.main-content .image img {
  width: 100%;
}

.main-content .form {
  border-radius: 10px;
  backdrop-filter: blur(15px);
  padding: 30px 20px;
  position: relative;
  margin-top: -30px;
  border: 1px solid #e4e0e7;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),
    rgba(185, 57, 201, 0.36);
  box-shadow: 4.91463px 0px 26.8px rgba(242, 108, 255, 0.25);
}

.main-content .text {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: "Satoshi-Bold";
  letter-spacing: 1px;
  width: 100%;
  margin: 0 auto 5px;
}

.main-content .sub-text {
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-family: "Satoshi-Bold";
  line-height: 30px;
  letter-spacing: 1px;
  width: 100%;
}

.main-content .sub-text-p {
  text-align: center;
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 10px;
}

.main-content .code-number {
  display: flex;
  border: none;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.main-content input {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  padding: 14px 10px;
  border-radius: 12px;
  width: 100%;
  font-size: 18px;
  color: #fff;
  position: relative;
  z-index: 3;
}

.main-content input::placeholder {
  color: #aca3a9;
}

.main-content .form-text {
  margin-top: 10px;
}

.main-content .input {
  width: 81%;
}

.main-content .fixed-cnt {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  padding: 13px 10px;
  background: transparent;
  /* font-weight: 600; */
  color: #fff;
  font-size: 18px;
}

.main-content button {
  border-radius: 38px;
  border: none;
  padding: 14px 8px;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-style: normal;
  font-family: "Satoshi-Bold";
  line-height: normal;
  margin-top: 20px;
  cursor: pointer;
  z-index: 2;
  background: linear-gradient(185.03deg, #eb8ef5 -32.98%, #a421b1 95.84%);
  transition: all .2s linear;
}
.main-content button:hover {
  opacity: 0.6;
}
.form-text p {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.condition {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-top: 14px;
}

/* .condition span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ACACB8;
} */
.condition a {
  color: #9f9f9f;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
}

.condition a:hover {
  color: #eb8ef5;
}

.form .trop-image {
  position: absolute;
  right: -240px;
  bottom: -105px;
}

.form .trop-image img {
  width: 320px;
}

.form .lp-loader {
  background: #0172fb;
  border: none;
  padding: 14px 8px;
  color: #fff;
  border-radius: 38px;
  text-align: center;
  font-family: "Satoshi-Bold";
  font-size: 16px;
  margin-top: 20px;
  position: relative;
}

/* otp */

.form.otp .otp-inputs {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}

.form.otp .otp-inputs input {
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  background: transparent;
}

.form.otp .resend {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.form.otp .resend .resend-otp {
  color: #FEB4AD;
  font-family: "Satoshi-Bold";
  margin-left: 5px;
  cursor: pointer;
}

.form.otp .resend .resend-otp:hover {
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

.form.otp .sub-otp-text {
  margin-bottom: 10px;
  color: #fff;
}

.form.otp .resend span:hover {
  color: #fff;
  cursor: pointer;
}

.form p.invalid {
  font-size: 12px;
  margin-top: 14px;
  color: #FEB4AD;
}

@media (max-width: 776px) {
  .main-content {
    padding-bottom: 10px;
    max-width: 95%;
  }

  .form .trop-image img {
    width: 200px;
  }
}
